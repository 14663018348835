import {SignatureEmbedding} from "../../../fusina-signatures/entities/SignatureEmbedding";
import {DocumentCard, DocumentCardActivity, DocumentCardDetails, DocumentCardType} from "@fluentui/react";
import * as React from "react";
import {useMemo} from "react";
import {RoleSignatureLabels} from "../../../fusina-authz/entities/RoleEnum";

export function SignatureCard(props: { onClickHref?: string, signature: SignatureEmbedding }) {
    const date = useMemo(() =>
            props?.signature?.date ? new Date(props.signature.date) : undefined,
        [props.signature.date])

    return <DocumentCard type={DocumentCardType.compact} onClickHref={props.onClickHref}
                         onClickTarget="_blank"
                         style={{height: "3.5em", minWidth: 150}}>
        {/*<DocumentCardPreview previewImages={[{*/}
        {/*    previewIconProps: {iconName: 'PDF'},// style: {root: {fontSize: '3em'}}},*/}
        {/*    width: 30,*/}
        {/*}]}/>*/}
        <DocumentCardDetails>
            {/*<DocumentCardTitle title="" shouldTruncate />*/}
            <DocumentCardActivity
                activity={`${RoleSignatureLabels[props.signature.role] ?? '?'} ${date?.toLocaleDateString?.(undefined, {dateStyle: "short"}) ?? ""}`}
                people={[{
                    name: props.signature.userEmail,
                    profileImageSrc: "",
                    initials: emailToInitials(props.signature.userEmail)
                }]}/>
        </DocumentCardDetails>
    </DocumentCard>;
}


export function emailToInitials(email: string): string {
    const user = email.split('@')[0]
    const filtered = user.replace(/[^a-zA-Z]+/g, ' ').toUpperCase()
    const split = filtered.split(' ')
    const initials = split.map(w => w.substring(0, 1))
    return initials.slice(0, 2).join('')
}
